import React, { Component } from 'react';
import { load, trackPageview } from 'fathom-client';
// Initialize Fathom Analytics
load(process.env.REACT_APP_FA_CODE);
const fathomTracker = (WrappedComponent, options = {}) => {
    const HOC = class extends Component {
        componentDidMount() {
            const page = this.props.location.pathname;
            trackPageview({ url: page });
        }
        componentDidUpdate(prevProps) {
            const currentPage = prevProps.location.pathname;
            const nextPage = this.props.location.pathname;

            if (currentPage !== nextPage) {
                trackPageview({ url: nextPage });
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
};

export default fathomTracker;
